import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Teams from "../components/teams"
import Faq from "../components/faq"
import Svg from "../components/svg"
import eelbeyglu from "../assets/img/allteam.png"

const About = () => (
    <Layout>
        <SEO title="Tanışalım" description="8 milyar nüfuslu dünyada hedef kitlenize ulaşacak aksiyonlar, yaratıcı fikirler, işlevsel tasarımlar ve hedef odaklı stratejiler kurguluyoruz." />
        <div className="about container">
            <div className="about-top">
                <h1>Analiz. Trafik Edinme.<br /> Dönüşüm.</h1>
                <h3>HAKKIMIZDA</h3>
                <p>Dört kelime. Tek yanıt: Flatart.
8 milyar nüfuslu dünyada hedef kitlenize ulaşacak aksiyonlar, yaratıcı fikirler, işlevsel tasarımlar ve hedef odaklı stratejiler kurguluyoruz.</p>
                <div className="about-top_row container row">
                    <div className="col-md-3">
                        <span>$10M+</span>
                        <p>Yönetilen Reklam Yatırımları</p>
                    </div>
                    <div className="col-md-3">
                        <span>123+</span>
                        <p>Marka ile İş Birliği</p>
                    </div>
                    <div className="col-md-3">
                        <span>%65+</span>
                        <p>Uzun Vadeli Çalışma Oranı</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="">
            {/* <img alt="alt text" src={lion}></img> */}
            <Svg />
        </div>
        <div className="about container">
            <div className="methodology">
                <h2 style={{marginTop:0}}>Markanızı Büyütme Metodolojimiz</h2>
                <p className="med-p">Analiz araçları ile verilerinizi anlamlandırıyor, elde ettiğimiz sonuçları pazarlama faaliyetlerinde kullanıyoruz. Müşterilerinizle etkileşime geçmenizi sağlayarak, dönüşüme gidecek yolları inşa ediyor ve tüm sonuçları raporluyoruz.</p>
                <div className="circles">
                    <div className="circle circle-mobile">
                        <div className="circle-content">
                            <h3>Analiz</h3>
                            <p>
                                - Analiz Danışmanlığı<br />
                                - Brand Audit<br />
                                - UI/UX Audits<br />
                                - SEO/SEM Audits
                        </p>
                        </div>
                    </div>
                    <div className="circle">
                        <div className="circle-content">
                            <h3>Trafik Edinme</h3>
                            <p>
                                - SEO/SEM<br />
                                - Dijital Reklamlar<br />
                                - Sosyal Medya
                        </p>
                        </div>
                    </div>
                    <div className="circle">
                        <div className="circle-content">
                            <h3>Dönüşüm</h3>
                            <p>
                                - UI/UX Tasarım <br />
                                - Lead Generation<br />
                                - Website Geliştirmeleri<br />
                                - CRO
                        </p>
                        </div>
                    </div>
                    <div className="circle">
                        <div className="circle-content">
                            <h3>Büyüme</h3>
                            <p>
                                - Markaya Özel Stratejik Plan Yaratma<br />
                                - Yüksek Kaliteli Trafik Edinme<br />
                                - Website İyileştirme ve Tasarımı<br />
                                - Dönüşüm Oranlarını Artırma<br />
                                - Marka Farkındalığını ve Kârı Artırma
                        </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="team" id="ekip">
                <div className="team-content">
                    <h2>Markaları büyüten <br /> ekip üyeleri.</h2>
                    <p>Yetenekli. Donanımlı. Profesyonel. Yenilikçi. <br />Dinamik. Çözüm Odaklı. Flatart Ekibi.</p>
                    <br />
                    <img style={{width: '35%'}} alt="Alaattin Emre Elbeyoğlu" src={eelbeyglu}></img>
                </div>
                <Teams />
            </div>
            <div className="hiring" style={{marginTop: -80}} id="ik">
                <h2>Açık pozisyonlar</h2>
                <p style={{ textAlign: 'center' }}>Aşağıdaki açık pozisyonlardan senin için uygun olan <br />varsa aramıza katılmak için başvurabilirsin.</p>
                <Faq />
            </div>


        </div>
    </Layout >
)

export default About
