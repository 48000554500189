import React from "react"
import { Link } from "gatsby"



const Faq = () => (

    // <input type="text" name="title" value={this.state.title} onChange={this.handleChange.bind(this)} />

    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>Front-end Developer</h3>
            <p>Web tasarımcıları tarafından tasarlanan website ve uygulama görünümünü,  HTML, CSS ve JavaScript ile kodlayarak yayına hazır hale getirir. </p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Hemen Başvur.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>Digital Marketing Specialist</h3>
            <p>E-posta, sosyal medya ağları, e-posta, arama motorları gibi online araçlar kullanarak dijital pazarlama stratejileri oluşturur, marka hedeflerine yönelik reklam kampanyalarını yönetir.</p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Hemen Başvur.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>Social Media Manager</h3>
            <p>Temsil ettiği markaların imajına uygun sosyal medya stratejileri geliştirir, en uygun iletişim dilini kullanarak hedef kitle ile etkileşime geçecek çalışmalar üretir.</p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Hemen Başvur.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>Brand Manager</h3>
            <p>Temsil ettiği markaların dijital ihtiyaçlarını tespit eder, büyüme planı doğrultusunda projeler geliştirir ve markalaşma sürecini takip ederek gereken iyileştirmelerin gerçekleştirilmesini sağlar.</p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Hemen Başvur.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab5sec">
            <label htmlFor="tab5"> </label>
            <h3>Graphic Designer</h3>
            <p>Markanın mesajını görselleştirir. Bunun için de diğer departmanlar ile ortaklaşa çalışarak markanın diline uygun tasarımlar ortaya çıkarır. </p>
            <p><a href="https://forms.flatart.com.tr/index.php?r=app%2Fform&id=3">Hemen Başvur.</a></p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    </div>
)

export default Faq
