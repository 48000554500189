import React from "react"
import eelbeyglu from "../assets/img/teams/newmember1.png"
import myildirim from "../assets/img/teams/newmember2.png"
import mualla from "../assets/img/teams/member5.png"
import simay from "../assets/img/teams/member4.png"
import melisa from "../assets/img/teams/member2.png"





const Teams = () => (
    

    <div className="team-list py60">
        
        <div className="team-list__by">
            <div className="image"><img alt="Alaattin Emre Elbeyoğlu" src={eelbeyglu}></img></div>
    
            {/* <p className="description">“ Aaa ne yiyorsunuz bakim” Valla bi’ şey yemiyoruz Derya <span role="img" aria-label>🤭</span>Hepimiz diyetimize dikkat ediyoruz. </p> */}
        </div>
    
        <div className="team-list__by">
        <div className="image"><img alt="Murthan Yıldırım" src={myildirim}></img></div>

            {/* <p className="description">“ Aaa ne yiyorsunuz bakim” Valla bi’ şey yemiyoruz Derya <span role="img" aria-label>🤭</span>Hepimiz diyetimize dikkat ediyoruz. </p> */}
        </div>

        <div className="team-list__by">
           <div className="image"><img alt="alt text" src={melisa}></img></div>
          
            {/* <p className="description">“ Aaa ne yiyorsunuz bakim” Valla bi’ şey yemiyoruz Derya <span role="img" aria-label>🤭</span>Hepimiz diyetimize dikkat ediyoruz. </p> */}
        </div>
        
        <div className="team-list__by">
            <div className="image"><img alt="alt text" src={simay}></img></div>
           
            {/* <p className="description">“ Aaa ne yiyorsunuz bakim” Valla bi’ şey yemiyoruz Derya <span role="img" aria-label>🤭</span>Hepimiz diyetimize dikkat ediyoruz. </p> */}
        </div>
       
            {/* <p className="description">“ Aaa ne yiyorsunuz bakim” Valla bi’ şey yemiyoruz Derya <span role="img" aria-label>🤭</span>Hepimiz diyetimize dikkat ediyoruz. </p> */}
        
    </div> 
   
        
    
    
)

export default Teams
