import React from "react"
import video from "../assets/img/gallery/video1.mp4"


const Svg = () => (

    <div className="svgContainer">
        <div className="svg-close"></div>
        <video width="" height="600" className="video" autoPlay muted loop>
            <source src={video} type="video/mp4" />
        </video>

        {/* <video className="video" width="600" height="600" autoPlay="" src={video}></video> */}
        <svg className="lionSvg" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1000 1000" style={{ enableBackground: "new 0 0 1000 1000" }} space="preserve">
            <mask id="maskLayer">
                <rect width="100%" height="100%" fill="#fff"></rect>
                <g>
                    <polygon className="st0" points="584.68,420.36 656.42,407 633.36,447.38 566.18,458.93 552.78,515.61 543.11,469.08 584.68,420.36     " />
                    <path className="st0" d="M494.39,646.62l40.03,26.54l44.42,7.95l-13.31,27.02l-49.34-11.64c0,0-17.05-38.27-21.79-48.46V646.62   L494.39,646.62z" />
                    <polygon className="st0" points="601.68,593.64 690.6,495.66 687.13,420.31 714.73,510.64 614.86,626.37 601.68,593.64  " />
                    <polygon className="st1" points="612.88,663.67 619.43,712.57 575.49,752.38 669.16,707.17 629.36,641.61 612.88,663.67  " />
                    <polygon className="st0" points="612,307.18 759.98,223.48 801.89,303.14 743.24,354.41 771.53,298.17 751.89,256.83 612,307.18  " />
                    <polygon className="st2" points="599.17,317.81 865.91,556.03 777.02,676.19 836.86,564.51 599.17,317.81  " />
                    <polygon className="st1" points="713.8,457.26 819.77,568.24 747.63,715.07 796.27,586.96 732.65,515.56 713.8,457.26  " />
                    <polygon className="st3" points="723.64,526.11 782.56,590.52 718.77,749.52 748.64,625.97 694.43,565.12 723.64,526.11  " />
                    <polygon className="st4" points="688.14,574.88 734.1,631.86 698.07,764.81 711.34,664.81 661.34,604.18 688.14,574.88  " />
                    <polygon className="st1" points="566.35,323.57 750.53,210.39 718.85,167.11 566.35,323.57  " />
                    <polygon className="st2" points="552.65,318.78 641.92,147.21 706.55,161.62 552.65,318.78  " />
                    <polygon className="st3" points="529.8,296.5 589.55,124.45 530.42,97.61 529.8,296.5  " />
                    <polygon className="st4" points="617.1,315.48 871.84,547.33 898.46,508.27 617.1,315.48  " />
                    <polygon className="st3" points="637.97,311.57 850.66,429.32 884.23,483.53 637.97,311.57  " />
                    <polygon className="st3" points="740.73,356.87 815.77,372.95 837.22,407.66 740.73,356.87  " />
                    <polygon className="st3" points="755.89,351.99 788.62,329.23 807.34,361.04 755.89,351.99  " />
                    <polygon className="st2" points="653.79,614.9 701.37,673.34 671.36,798.33 679.75,692.49 638.28,632.78 653.79,614.9  " />
                    <polygon className="st3" points="667.71,723.6 663.45,777.46 633.8,802.64 625.1,744.47 667.71,723.6  " />
                    <polygon className="st4" points="615.74,749.04 626.5,808.79 596.41,834.36 581.07,766.04 615.74,749.04  " />
                    <polygon className="st2" points="570.13,771.4 587.22,842.14 549.04,874.65 526.81,792.66 570.13,771.4  " />
                    <polygon className="st4" points="540.83,310.43 598.73,129.94 630.41,143.08 540.83,310.43  " />
                    <polygon className="st0" points="494.39,648.47 468.95,614.37 436.48,600.89 431.78,571.32 479.93,576.99 494.39,597.15 508.76,576.99    556.91,571.32 552.25,600.89 519.78,614.37 494.39,648.47  " />
                    <polygon className="st0" points="404.1,420.36 332.27,407 355.42,447.38 422.51,458.93 435.95,515.61 445.62,469.08 404.1,420.36  " />
                    <path className="st0" d="M494.39,646.62l-40.07,26.54l-44.38,7.95l13.22,27.02l49.34-11.64c0,0,17.09-38.27,21.88-48.46V646.62   L494.39,646.62z" />
                    <polygon className="st0" points="387.05,593.64 298.13,495.66 301.64,420.31 273.92,510.64 368.73,628.17 387.05,593.64  " />
                    <polygon className="st1" points="375.9,663.67 369.3,712.57 413.24,752.38 319.61,707.17 359.38,641.61 375.9,663.67  " />
                    <polygon className="st0" points="424.49,713.23 443.99,740.07 494.39,737.7 544.74,740.07 564.33,713.23 559.41,762 494.43,792.01    494.39,787.88 494.26,792.01 429.32,762 424.49,713.23  " />
                    <polygon className="st0" points="376.73,307.18 228.71,223.48 186.84,303.14 245.58,354.41 217.24,298.17 236.8,256.83 376.73,307.18     " />
                    <polygon className="st2" points="389.56,317.81 122.83,556.03 211.71,676.19 151.87,564.51 389.56,317.81  " />
                    <polygon className="st1" points="274.97,457.26 169.05,568.24 241.14,715.07 192.47,586.96 256.08,515.56 274.97,457.26  " />
                    <polygon className="st3" points="265.09,526.11 206.17,590.52 269.97,749.52 240.05,625.97 294.31,565.12 265.09,526.11  " />
                    <polygon className="st4" points="300.54,574.88 254.63,631.86 290.66,764.81 277.44,664.81 327.39,604.18 300.54,574.88  " />
                    <polygon className="st1" points="422.33,323.57 238.24,210.39 269.97,167.11 422.33,323.57  " />
                    <polygon className="st2" points="436.17,318.78 346.9,147.21 282.14,161.62 436.17,318.78  " />
                    <polygon className="st3" points="458.93,296.5 399.23,124.45 458.32,97.61 458.93,296.5  " />
                    <polygon className="st5" points="494.39,330.77 472.6,300.46 467.32,78.63 494.39,136.45 521.41,78.63 516.18,300.46 494.39,330.77     " />
                    <polygon className="st4" points="371.63,315.48 116.9,547.33 90.27,508.27 371.63,315.48  " />
                    <polygon className="st3" points="350.85,311.57 138.12,429.32 104.51,483.53 350.85,311.57  " />
                    <polygon className="st3" points="248,356.87 173,372.95 151.47,407.66 248,356.87  " />
                    <polygon className="st3" points="232.8,351.99 200.11,329.23 181.39,361.04 232.8,351.99  " />
                    <polygon className="st2" points="335.03,614.9 287.32,673.34 317.33,798.33 308.94,692.49 350.41,632.78 335.03,614.9  " />
                    <polygon className="st3" points="321.02,723.6 325.28,777.46 354.89,802.64 363.64,744.47 321.02,723.6  " />
                    <polygon className="st4" points="372.99,749.04 362.28,808.79 392.33,834.36 407.66,766.04 372.99,749.04  " />
                    <polygon className="st2" points="418.6,771.4 401.51,842.14 439.69,874.65 461.88,792.66 418.6,771.4  " />
                    <polygon className="st1" points="467.81,795.52 449.18,882.64 494.39,921 539.55,882.64 520.88,795.52 494.39,808.61 467.81,795.52     " />
                    <polygon className="st4" points="447.9,310.43 389.96,129.94 358.32,143.08 447.9,310.43  " />
                </g>
            </mask>


            <rect id="masked" width="100%" height="100%" fill="#fff"></rect>


        </svg>
        <div className="svg-close"></div>

    </div >
)

export default Svg
